










































  import Vue, { PropType } from 'vue';

  interface Option {
    value: string | number;
    hasExtraInput: boolean;
    warning: string;
    name: string;
    className: string;
  }

  export default Vue.extend({
    name: 'RadioInput',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      optionClass: {
        type: String,
        default: '',
      },
      options: {
        type: Array as PropType<Option[]>,
        required: true,
      },
      value: {
        type: [String, Number],
        required: true,
      },
    },
  });
