




  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView.vue';

  export default {
    components: {
      ErrorView,
    },
    name: 'NotFound',
    data() {
      return {
        error: ErrorType.PageNotFound,
      };
    },
  };
