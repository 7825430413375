









  import Icon from '@/components/Icon/Icon.vue';
  import Vue from 'vue';

  export default Vue.extend({
    components: {
      Icon,
    },
    props: {
      hasIcon: {
        type: Boolean,
      },
      translatedMessage: {
        type: String,
        default: '',
      },
      variant: {
        type: String,
        default: 'info',
        validator(value: string) {
          return ['info', 'success', 'error', 'warning'].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      classObject(): string[] {
        const variantClass = `notification-block--${this.variant}`;
        return [
          this.hasIcon ? 'notification-block--with-icon' : '',
          variantClass,
        ];
      },
      variantIcon(): string {
        let icon = '';
        switch (this.variant) {
          case 'warning':
            icon = 'notification-warning';
            break;
          case 'success':
            icon = 'notification-check';
            break;
          case 'error':
            icon = 'notification-warning';
            break;
          default:
            icon = 'notification-help';
        }
        return icon;
      },
    },
  });
