import { DirectiveOptions } from 'vue';

const appendToDirective: DirectiveOptions = {
  inserted(el, { value: target }) {
    if (target instanceof Element || target instanceof HTMLDocument) {
      target.appendChild(el);
    }
  },

  unbind(el, binding, vnode) {
    function destroy() {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }

    if (vnode.context && vnode.data && vnode.data.transition) {
      vnode.context.$once('hook:afterLeave', destroy);
      vnode.context.$once('hook:destroyed', () => {
        vnode.context && vnode.context.$off('hook:afterLeave', destroy);
        destroy();
      });
    } else {
      destroy();
    }
  },
};

export default appendToDirective;
