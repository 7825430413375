import Vue from 'vue';
import api from '@/api';

export const ENTITIES_FOLDERS_DELETE = 'ENTITIES:FOLDERS:DELETE';
export const ENTITIES_FOLDERS_SET = 'ENTITIES:FOLDERS:SET';
export const ENTITIES_FOLDERS_UPDATE = 'ENTITIES:FOLDERS:UPDATE';
export const SET_LOADING_FOLDERS_ERROR = 'SET_LOADING_FOLDERS_ERROR';
export const RESET_LOADING_FOLDERS_ERROR = 'RESET_LOADING_FOLDERS_ERROR';

const systemFolderTypes = [
  'inbox',
  'flagged',
  'drafts',
  'sent',
  'junk',
  'trash',
];
const systemFolderTypesReversed = systemFolderTypes.reverse();

function systemFoldersBeforeAlphabetical(a, b) {
  if (a.type !== b.type) {
    return (
      systemFolderTypesReversed.indexOf(b.type) -
      systemFolderTypesReversed.indexOf(a.type)
    );
  }

  return a.display_name
    .toUpperCase()
    .localeCompare(b.display_name.toUpperCase());
}

function populateChildFolders(allFolders, folder) {
  const children = allFolders
    .filter(({ parent_id }) => parent_id === folder.id)
    .map((child) => populateChildFolders(allFolders, child))
    .sort(systemFoldersBeforeAlphabetical);

  return {
    ...folder,
    children,
  };
}

export default {
  state: {
    byId: {},
    quota: {
      storage_used: null,
      storage_total: null,
    },
    loadingFoldersError: false,
  },

  getters: {
    allFolders: (state) => Object.values(state.byId),
    folderById: (state) => (id) => id === null ? undefined : state.byId[id],
    folderTree(state, getters) {
      return getters.allFolders
        .filter(({ parent_id }) => !parent_id)
        .map((folder) => populateChildFolders(getters.allFolders, folder))
        .sort(systemFoldersBeforeAlphabetical);
    },
    systemFolders(state, getters) {
      return systemFolderTypes.reduce(
        (folders, type) => ({
          ...folders,
          [type]:
            getters.allFolders.find(
              (folder) => folder.type === type && !folder.parent_id
            ) || {},
        }),
        {}
      );
    },
    loadingFoldersError: (state) => state.loadingFoldersError,
  },

  actions: {
    // API actions
    async loadFolders({ commit }, payload) {
      const noUpdateActivity = payload && payload.noUpdateActivity;
      return api.folders.all({ noUpdateActivity }).then(({ folders }) => {
        commit(ENTITIES_FOLDERS_SET, { folders });
        return folders;
      });
    },
    async createFolder({ commit }, { folder }) {
      return api.folders.create({ folder }).then((folder) => {
        commit(ENTITIES_FOLDERS_UPDATE, { folders: [folder] });
        return folder;
      });
    },
    async deleteFolder({ commit }, { folder }) {
      return api.folders.delete({ id: folder.id }).then((response) => {
        commit(ENTITIES_FOLDERS_DELETE, { id: folder.id });
        return response;
      });
    },
    async patchFolder({ commit, getters }, { id, properties }) {
      return api.folders.update({ id, properties }).then((folder) => {
        if (id !== folder.id) {
          // Create a folderTree and extract all children of the patched folder
          const folderTree = populateChildFolders(getters.allFolders, { id });
          const extractChildren = (subTree) =>
            (subTree.children || []).reduce(
              (all, { children, ...child }) => [
                ...all,
                child,
                ...extractChildren({ ...child, children }),
              ],
              []
            );
          const children = extractChildren(folderTree);

          // Update children of the patched folder - change id and parent_id
          commit(ENTITIES_FOLDERS_UPDATE, {
            folders: children.map((child) => ({
              ...child,
              id: child.id.replace(id, folder.id),
              parent_id: child.parent_id.replace(id, folder.id),
            })),
          });

          // Remove old children from state
          children.forEach((child) =>
            commit(ENTITIES_FOLDERS_DELETE, { id: child.id })
          );

          // Remove old record of patched folder from state
          commit(ENTITIES_FOLDERS_DELETE, { id });
        }

        commit(ENTITIES_FOLDERS_UPDATE, { folders: [folder] });
        return folder;
      });
    },
    changeUnreadMessagesCountInFolder(
      { commit, getters },
      { folderId, unreadCount }
    ) {
      const folder = getters.folderById(folderId);

      if (folder) {
        commit(ENTITIES_FOLDERS_UPDATE, {
          folders: [
            {
              ...folder,
              unread_messages_count: Math.max(0, unreadCount),
            },
          ],
        });
      }

      return getters.folderById(folderId);
    },
    setLoadingFoldersError({ commit }, { error }) {
      commit(SET_LOADING_FOLDERS_ERROR, { error });
    },
    resetLoadingFoldersError({ commit }) {
      commit(SET_LOADING_FOLDERS_ERROR, { error: false });
    },
  },

  mutations: {
    [ENTITIES_FOLDERS_DELETE](state, { id }) {
      Vue.delete(state.byId, id);
    },
    [ENTITIES_FOLDERS_SET](state, { folders }) {
      state.byId = folders.reduce(
        (all, folder) => ({
          ...all,
          [folder.id]: folder,
        }),
        {}
      );
    },
    [ENTITIES_FOLDERS_UPDATE](state, { folders }) {
      folders.forEach((folder) => {
        Vue.set(state.byId, folder.id, { ...state.byId[folder.id], ...folder });
      });
    },
    [SET_LOADING_FOLDERS_ERROR](state, { error }) {
      state.loadingFoldersError = error;
    },
    [RESET_LOADING_FOLDERS_ERROR](state) {
      state.loadingFoldersError = false;
    },
  },
};
