















  import Vue, { PropType } from 'vue';
  import EmptyView from '@/components/EmptyView.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { SETTINGS_PGP_KEYRING } from '@/router/named-routes';
  import emptyStateErrorImage from '@/assets/img/empty-state-error.svg';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export enum ErrorType {
    PageNotFound,
    MessageNotFound,
    FolderNotFound,
    ContactNotFound,
    KeyNotFound,
    LoadingFoldersError,
    LoadingMessagesError,
    LoadingContactsError,
    LoadingKeysError,
    LoadingContactGroupsError,
    LoadingAliasesError,
    LoadingFiltersError,
  }

  export default Vue.extend({
    components: {
      EmptyView,
      Icon,
      SupportCenterLink,
    },
    name: 'ErrorView',
    props: {
      errorType: {
        type: Number as PropType<ErrorType>,
        required: true,
      },
    },
    data() {
      return {
        header: null as string | null,
        content: null as string | null,
        emptyStateErrorImage,
      };
    },
    created() {
      this.updateData();
    },
    computed: {
      isSearchSupportLinkVisible(): boolean {
        return this.errorType === ErrorType.MessageNotFound;
      },
      contactUsParagraph(): string {
        return this.$gettext(
          'Please try again later or contact us at <a href="/mail/compose?mailTo=support@startmail.com">support@startmail.com</a> for more help.'
        );
      },
    },
    methods: {
      updateData(): void {
        switch (this.errorType) {
          case ErrorType.PageNotFound:
            this.setPageNotFound();
            break;
          case ErrorType.MessageNotFound:
            this.setMessageNotFound();
            break;
          case ErrorType.FolderNotFound:
            this.setFolderNotFound();
            break;
          case ErrorType.ContactNotFound:
            this.setContactNotFound();
            break;
          case ErrorType.KeyNotFound:
            this.setKeyNotFound();
            break;
          case ErrorType.LoadingFoldersError:
            this.setLoadingFoldersError();
            break;
          case ErrorType.LoadingMessagesError:
            this.setLoadingMessagesError();
            break;
          case ErrorType.LoadingContactsError:
            this.setLoadingContactsError();
            break;
          case ErrorType.LoadingKeysError:
            this.setLoadingKeysError();
            break;
          case ErrorType.LoadingContactGroupsError:
            this.setLoadingContactGroupsError();
            break;
          case ErrorType.LoadingAliasesError:
            this.setLoadingAliasesError();
            break;
          case ErrorType.LoadingFiltersError:
            this.setLoadingFiltersError();
            break;
        }
      },
      setPageNotFound(): void {
        this.header = this.$gettext('This page can’t be found.');
        this.content = this.$gettext(
          'Please check that the URL has the correct spelling. If you think this is an error, please contact us at <a href="/mail/compose?mailTo=support@startmail.com">support@startmail.com</a> for more help.'
        );
      },
      setMessageNotFound(): void {
        this.header = this.$gettext('That email can’t be found.');
        this.content = this.$gettext(
          'Looking for an email? Try using the search tool above.'
        );
      },
      setFolderNotFound(): void {
        this.header = this.$gettext('That folder can’t be found.');
        this.content = this.$gettext(
          'Looking for a folder? Try browsing through your existing folders in the navigation.'
        );
      },
      setContactNotFound(): void {
        this.header = this.$gettext('That contact can’t be found.');
        this.content = this.$gettext(
          'Looking for a contact? Try using the search tool above.'
        );
      },
      setKeyNotFound(): void {
        const url = this.$router.resolve({
          name: SETTINGS_PGP_KEYRING,
        }).href;
        this.header = this.$gettext('That PGP key can’t be found.');
        const content = this.$gettext(
          'If you are looking for a specific key, <a href="%{url}">go to your PGP keyring</a> and browse through your keys.'
        );
        this.content = this.$gettextInterpolate(content, { url });
      },
      setLoadingFoldersError(): void {
        this.header = this.$gettext(
          'We are sorry, your folders can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingMessagesError(): void {
        this.header = this.$gettext(
          'We are sorry, your messages can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingContactsError(): void {
        this.header = this.$gettext(
          'We are sorry, your contacts can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingKeysError(): void {
        this.header = this.$gettext(
          'We are sorry, your keys can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingContactGroupsError(): void {
        this.header = this.$gettext(
          'We are sorry, your contact groups can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingAliasesError(): void {
        this.header = this.$gettext(
          'We are sorry, your aliases can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
      setLoadingFiltersError(): void {
        this.header = this.$gettext(
          'We are sorry, your filters can’t be loaded right now.'
        );
        this.content = this.contactUsParagraph;
      },
    },
  });
