import { DirectiveOptions } from 'vue';

const testDirective: DirectiveOptions = {
  inserted(el, binding) {
    if (
      binding.arg &&
      ['development', 'test'].includes(process.env.NODE_ENV as string)
    ) {
      const value = binding.value
        ? `${binding.arg}:${binding.value}`
        : binding.arg;
      el.setAttribute('data-test', value);
    }
  },
};

export default testDirective;
