










































































  import Icon from '@/components/Icon/Icon.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import Mousetrap from 'mousetrap';
  import 'mousetrap-global-bind';
  import Vue from 'vue';

  interface MousetrapStaticWithGlobalUnbind extends Mousetrap.MousetrapStatic {
    unbindGlobal(keys: string | string[], action?: string): void;
  }

  export default Vue.extend({
    name: 'Modal',
    components: {
      Icon,
      Spinner,
    },
    props: {
      closable: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      modalIsOpen: {
        type: Boolean,
        default: false,
      },
      showLoadingSpinner: {
        type: Boolean,
        default: false,
      },
      hasHeader: {
        type: Boolean,
        default: true,
      },
      dialogClass: {
        type: String,
        required: false,
        default: '',
      },
      narrow: {
        type: Boolean,
        default: false,
      },
      includeInRenderTree: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      const data: {
        beforeCloseCallback: (args: { preventDefault: () => void }) => void;
        isOpen: boolean;
        modalState: {
          loading: boolean;
        };
      } = {
        beforeCloseCallback: () => {},
        isOpen: this.modalIsOpen,
        modalState: {
          loading: false,
        },
      };
      return data;
    },
    computed: {
      dialog(): HTMLDialogElement {
        return this.$refs.dialog as HTMLDialogElement;
      },
    },
    watch: {
      isOpen(newValue: boolean) {
        newValue ? this.onOpen() : this.onClose();
      },
      loading(newValue: boolean) {
        this.modalState.loading = newValue;
      },
      'modalState.loading': () => {
        if (document.activeElement) {
          (document.activeElement as HTMLElement).blur();
        }
      },
      modalIsOpen(newValue: boolean) {
        this.isOpen = newValue;
      },
    },
    mounted() {
      if (this.isOpen) {
        this.onOpen();
      }
    },
    destroyed() {
      this.onClose();
    },
    provide(): { modalState: { loading: boolean } } {
      return {
        modalState: this.modalState,
      };
    },
    methods: {
      onOpen() {
        Mousetrap.bindGlobal('esc', this.userTogglesModal);
        document.documentElement.addEventListener(
          'focusin',
          this.onDocumentFocusIn,
          false
        );
      },
      onClose() {
        (Mousetrap as MousetrapStaticWithGlobalUnbind).unbindGlobal(['esc']);
        document.documentElement.removeEventListener(
          'focusin',
          this.onDocumentFocusIn,
          false
        );
        this.beforeCloseCallback = () => {};
      },
      onDocumentFocusIn(ev: Event) {
        if (
          this.modalState.loading ||
          (this.dialog && !this.dialog.contains(ev.target as Node))
        ) {
          this.dialog.focus();

          ev.preventDefault();
        }
      },
      setLoading(loading: boolean) {
        this.modalState.loading = loading;
      },
      setBeforeCloseCallback(
        callback: (args: { preventDefault: () => void }) => void
      ) {
        this.beforeCloseCallback = callback;
      },
      userTogglesModal() {
        if (!this.modalState.loading) {
          this.toggleModal();
        }
      },
      toggleModal() {
        if (this.isOpen) {
          let prevented = !this.closable;
          if (this.beforeCloseCallback) {
            this.beforeCloseCallback({
              preventDefault() {
                prevented = true;
              },
            });
          }

          if (prevented) {
            return; //the close was prevented so lets stop
          }
        }

        this.isOpen = !this.isOpen;
        this.$emit('modalToggled', this.isOpen);
      },
    },
  });
