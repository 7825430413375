import { DirectiveOptions } from 'vue';

const focusDirective: DirectiveOptions = {
  inserted(el, binding) {
    if (binding.value) {
      window.setTimeout(() => el.focus(), 0);
    }
  },
};

export default focusDirective;
