import api from '@/api';
import { SET_TOTAL } from '@/store/pagination';
import { SORT_ORDER } from '@/store/sorting/sorting';
import Vue from 'vue';

export const ENTITIES_MESSAGES_ADD_FULL = 'ENTITIES:MESSAGES:ADD_FULL';
export const ENTITIES_MESSAGES_DELETE_FULL = 'ENTITIES:MESSAGES:DELETE_FULL';
export const ENTITIES_MESSAGES_UPDATE = 'ENTITIES:MESSAGES:UPDATE';
export const ENTITIES_MESSAGES_DELETE = 'ENTITIES:MESSAGES:DELETE';
export const ENTITIES_MESSAGES_SET_DEBOUNCE_LOADING =
  'ENTITIES:MESSAGES:SET_LOADING';

function firstArray(...maybeArrays) {
  return maybeArrays.find((maybeArray) => Array.isArray(maybeArray)) || [];
}

/**
 * Creates an updated message object given the stored message and a message update.
 * Since this function is also used on message summaries (where the body and pgp status are not fully read)
 * this method updates those fields intelligently, preferring the complete one.
 *
 * @param message The currently stored message.
 * @param update The updates to the message.
 * @returns The updated message.
 */
function _mergeMessageWithUpdate(message, update) {
  if (!message) {
    return update; // Quick path in case message was not yet cached.
  }

  return {
    ...message,
    ...update,
    to: firstArray(update.to, message.to).filter(
      (recipient) => typeof recipient.email === 'string'
    ),
    cc: firstArray(update.cc, message.cc).filter(
      (recipient) => typeof recipient.email === 'string'
    ),
    bcc: firstArray(update.bcc, message.bcc).filter(
      (recipient) => typeof recipient.email === 'string'
    ),
    files: firstArray(
      update.files && update.files.length > 0 && update.files,
      message.files
    ),
    body:
      typeof update.body === 'string'
        ? update.body
        : typeof message.body === 'string'
        ? message.body
        : '',
  };
}

/**
 * Replaces the given old message-id by the new message id, if it occurs as part of a path.
 *
 * @param oldId The current folder-message-id.
 * @param newId The new folder-message-id.
 * @param updatedMessage Return updated message.
 */
function _fixMessageURLs(oldId, newId, updatedMessage) {
  if (typeof updatedMessage.body !== 'string') {
    return updatedMessage;
  }

  const oldPath = `/api/Messages/${oldId}/Attachments/`;
  const newPath = `/api/Messages/${newId}/Attachments/`;
  return {
    ...updatedMessage,
    body: updatedMessage.body.replace(oldPath, newPath), // When there are attachments present in the message body, we do a replacement
    files: updatedMessage.files.map((file) => ({
      ...file,
      url: file.url.replace(oldPath, newPath),
    })), // update file url paths if they are present
  };
}

// Delete a message from the store.
// Updates fullMessageIds state if applicable.
// Clears the active message if applicable.
function _deleteMessage(dispatch, commit, getters, id) {
  if (getters.activeMessageId === id) {
    dispatch('clearActiveMessage');
  }
  if (getters.hasFullMessage(id)) {
    commit(ENTITIES_MESSAGES_DELETE_FULL, { id });
  }
  commit(ENTITIES_MESSAGES_DELETE, { id });
}

// Clear all messages from a folder in the store.
// Used when a folder is emptied (e.g., trash and junk).
function _clearFolder(dispatch, commit, getters, state, folderId) {
  // Update active messages.
  if (getters.activeFolder.id === folderId) {
    dispatch('setActiveMessages', { ids: [] });
    commit(`pagination/${SET_TOTAL}`, 0);
  }

  // Update unread count.
  dispatch('changeUnreadMessagesCountInFolder', {
    folderId: getters.systemFolders.junk.id,
    unreadCount: 0,
  });

  // Update message cache.
  for (const [id, message] of Object.entries(state.byId)) {
    if (message.folderId === folderId) {
      _deleteMessage(dispatch, commit, getters, id);
    }
  }
}

const state = {
  // Maps message id to cached message (either a summary or the
  // full message):
  byId: {},
  // Message ids of cached full messages (using array because Set
  // is not well-supported in this context):
  fullMessageIds: [],
  // State to add loading state to  message list while debounced
  // message loading is triggered from the pagination:
  debouncedMessageLoading: false,
};

const getters = {
  messageById: (state) => (id) => state.byId[id],
  hasFullMessage: (state) => (id) => state.fullMessageIds.includes(id),
};

const actions = {
  // Get message summaries for the active folder and query. Caches the result in the store.
  async loadMessages({ commit, dispatch, getters, rootState }, payload) {
    const folderId = rootState.folders.activeFolderId;
    const query = rootState.search.activeSearchQuery;
    const offset = getters['pagination/offset'];
    const limit = rootState.pagination.pageSize;
    const sortOrder = rootState.sorting.sortOrder;
    const order = SORT_ORDER.get(sortOrder);
    const filter = rootState.filters.activeFilters;
    const noUpdateActivity = payload && payload.noUpdateActivity;

    const { total, messages } = await api.messages.get({
      folderId,
      query,
      offset,
      limit,
      order,
      filter,
      noUpdateActivity,
    });
    commit(ENTITIES_MESSAGES_UPDATE, {
      // We need to strip the body and css_prefix here, as these
      // are returned as empty strings in the folder list by the api.
      messages: messages.map(({ body, css_prefix, ...message }) => message),
    });

    // Reload folders to update unread count.
    dispatch('loadFolders');

    // Update the active messages and pagination total (if the active view has not changed in the meantime).
    if (
      folderId === rootState.folders.activeFolderId &&
      query === rootState.search.activeSearchQuery &&
      offset === getters['pagination/offset'] &&
      limit === rootState.pagination.pageSize &&
      sortOrder === rootState.sorting.sortOrder &&
      filter === rootState.filters.activeFilters
    ) {
      dispatch('setActiveMessages', { ids: messages.map(({ id }) => id) });
      commit(`pagination/${SET_TOTAL}`, total);
    }
    return { total, messages };
  },

  // Gets and caches the message, if it was not yet cached. Marks the message as read if it was unread.
  async readMessage({ commit, dispatch, getters }, { id }) {
    const message = getters.messageById(id) || {};
    const folder = getters.folderById(message.folder_id);

    // Shortly after login the folders may still be loading resulting in an undefined folder. In that case,
    // we won't update the unread count just yet. This tends to happen with users that have lots of folders
    // and lots of messages.
    if (message.unread && folder) {
      dispatch('changeUnreadMessagesCountInFolder', {
        folderId: message.folder_id,
        unreadCount: folder.unread_messages_count - 1,
      });
    }

    if (!getters.hasFullMessage(id)) {
      // Load full message into cache.
      const message = await api.messages.getOne({ id });
      commit(ENTITIES_MESSAGES_UPDATE, { messages: [message] });
      commit(ENTITIES_MESSAGES_ADD_FULL, { id });
    } else if (message.unread) {
      // Mark as read if the message was already cached.
      dispatch('changeMessagesReadStatus', {
        messagesIds: [id],
        unread: false,
      });
    }

    return getters.messageById(id);
  },

  // Decrypt a message with the given passphrase. Caches the result in the store.
  async decryptMessage(
    { commit, getters, state },
    { id, pgpPassphrase, rememberPgpPassphrase }
  ) {
    // Set request object for decryption.
    const requestObj = { id, pgpPassphrase, rememberPgpPassphrase };

    // Decrypt and cache full message.
    const message = await api.messages.decrypt(requestObj);
    commit(ENTITIES_MESSAGES_UPDATE, { messages: [message] });
    commit(ENTITIES_MESSAGES_ADD_FULL, { id: message.id });

    // If rememberPgpPassphrase is set, remove all fully loaded encrypted messages from the cache.
    // This ensures that those messages are properly decrypted next time they are viewed.
    if (rememberPgpPassphrase) {
      const fullMessages = state.fullMessageIds.map(getters.messageById);
      const invalidMessages = fullMessages.filter(
        (message) =>
          message.id !== id &&
          message.flags.is_encrypted &&
          message.pgp.encryption.status !== 0
      );
      invalidMessages.forEach(({ id }) => {
        commit(ENTITIES_MESSAGES_DELETE_FULL, { id });
      });
    }

    return message;
  },

  // Delete all messages from the trash folder.
  async emptyTrash({ dispatch, commit, getters, state }) {
    const folderId = getters.systemFolders.trash.id;
    _clearFolder(dispatch, commit, getters, state, folderId);
    return await api.trash.empty();
  },

  // Delete all messages from the junk folder.
  async emptyJunk({ dispatch, commit, getters, state }) {
    const folderId = getters.systemFolders.junk.id;
    _clearFolder(dispatch, commit, getters, state, folderId);
    return await api.junk.empty();
  },

  // Delete a single message (actual delete, not moving to trash).
  async deleteMessage({ commit, dispatch, getters, rootState }, { messageId }) {
    // Update cache. The message may not be in cache, if it is is a draft.
    const message = getters.messageById(messageId);
    if (message) {
      // Update unread message count.
      if (message.unread) {
        dispatch('changeUnreadMessagesCountInFolder', {
          folderId: message.folder_id,
          unreadCount:
            getters.folderById(message.folder_id).unread_messages_count - 1,
        });
      }

      // Remove deleted message from the active messages.
      const activeMessagesIds = rootState.messages.activeMessagesIds;
      if (activeMessagesIds.includes(message.id)) {
        dispatch('setActiveMessages', {
          ids: activeMessagesIds.filter((id) => id !== message.id),
        });
      }

      // Remove deleted message from cache.
      _deleteMessage(dispatch, commit, getters, message.id);
    }

    return await api.messages.delete({ id: messageId });
  },

  async moveMessagesToFolder(
    { commit, dispatch, getters, rootState },
    { messagesIds, folderId }
  ) {
    const messages = messagesIds.map(getters.messageById);

    // Update the unread messages count on the source folders.
    const unreadMessages = messages.filter((message) => message.unread);
    const srcFolders = [
      ...new Set(unreadMessages.map(({ folder_id }) => folder_id)),
    ].map(getters.folderById);
    srcFolders.forEach((srcFolder) => {
      const unreadMessagesSrcFolder = unreadMessages.filter(
        ({ folder_id }) => folder_id === srcFolder.id
      );
      dispatch('changeUnreadMessagesCountInFolder', {
        folderId: srcFolder.id,
        unreadCount:
          srcFolder.unread_messages_count - unreadMessagesSrcFolder.length,
      });
    });

    // Update the unread messages count on the target folder.
    const dstFolder = getters.folderById(folderId);
    dispatch('changeUnreadMessagesCountInFolder', {
      folderId,
      unreadCount: dstFolder.unread_messages_count + unreadMessages.length,
    });

    // Note that we cannot update the affected messages in cache here,
    // because the message ids will change when they are moved in the mailbox.
    // These messages will be updated in cache after the actual move below.

    // Delete affected messages from the active messages.
    const activeMessagesIds = rootState.messages.activeMessagesIds;
    dispatch('setActiveMessages', {
      ids: activeMessagesIds.filter((id) => !messagesIds.includes(id)),
    });

    // Perform the move of the messages in the mailbox of the user.
    const updatesById = await api.messages.bulk({
      messages: messagesIds,
      update: { folder_id: folderId },
    });

    // Apply updates to cache, now that we know the new message ids.
    let newMessageIds = [];
    for (const [oldId, update] of Object.entries(updatesById)) {
      if (update.id && update.id !== oldId) {
        // Message id changed, merge update with cached data and fix URLs.
        const newId = update.id;
        const message = getters.messageById(oldId) || {};
        const updatedMessage = _mergeMessageWithUpdate(message, update);
        const messageWithBetterURLs = _fixMessageURLs(
          oldId,
          newId,
          updatedMessage
        );
        // Add updated message under the new id, then delete the old message.
        commit(ENTITIES_MESSAGES_UPDATE, { messages: [messageWithBetterURLs] });
        if (getters.hasFullMessage(oldId)) {
          commit(ENTITIES_MESSAGES_ADD_FULL, { id: newId });
        }
        _deleteMessage(dispatch, commit, getters, oldId);
        newMessageIds.push(newId);
      } else {
        // Message id did not change. Add message id if the update does not have one.
        commit(ENTITIES_MESSAGES_UPDATE, {
          messages: [{ ...update, id: oldId }],
        });
        newMessageIds.push(oldId);
      }
    }

    // Return the updated messages from the cache.
    return newMessageIds.map(getters.messageById);
  },

  async moveMessagesToTrash({ dispatch, getters }, { messagesIds }) {
    return dispatch('moveMessagesToFolder', {
      messagesIds,
      folderId: getters.systemFolders.trash.id,
    });
  },

  async markMessagesAsSpam({ dispatch, getters }, { messagesIds }) {
    return dispatch('moveMessagesToFolder', {
      messagesIds,
      folderId: getters.systemFolders.junk.id,
    });
  },

  async markMessagesAsNotSpam({ dispatch, getters }, { messagesIds }) {
    return dispatch('moveMessagesToFolder', {
      messagesIds,
      folderId: getters.systemFolders.inbox.id,
    });
  },

  async reportPhishing(_context, { messageId }) {
    return await api.messages.reportPhishing({ message: messageId });
  },

  async deleteTrashedMessages(
    { commit, dispatch, getters, rootState },
    { messagesIds }
  ) {
    // Delete affected messages from the active messages.
    const activeMessagesIds = rootState.messages.activeMessagesIds;
    dispatch('setActiveMessages', {
      ids: activeMessagesIds.filter((id) => !messagesIds.includes(id)),
    });

    // Remove messages from cache.
    messagesIds.forEach((id) => {
      _deleteMessage(dispatch, commit, getters, id);
    });

    // Delete messages from trash.
    const deletedMessagesById = await api.messages.bulk({
      messages: messagesIds,
      update: { delete: true },
    });

    // Return the ids of the deleted messages (cannot return messages, as they no longer exist).
    return Object.keys(deletedMessagesById);
  },

  async changeMessagesReadStatus(
    { commit, dispatch, getters },
    { messagesIds, unread }
  ) {
    // Update unread count.
    const messages = messagesIds.map(getters.messageById);
    const affectedMessages = messages.filter(
      (message) => message.unread !== unread
    );
    const affectedFolders = [
      ...new Set(affectedMessages.map(({ folder_id }) => folder_id)),
    ].map(getters.folderById);
    affectedFolders.forEach((folder) => {
      const affectedMessagesInFolder = affectedMessages.filter(
        ({ folder_id }) => folder_id === folder.id
      );
      const countUpdate = unread
        ? +affectedMessagesInFolder.length
        : -affectedMessagesInFolder.length;
      dispatch('changeUnreadMessagesCountInFolder', {
        folderId: folder.id,
        unreadCount: folder.unread_messages_count + countUpdate,
      });
    });

    // Update cached messages so that UI is updated without waiting for the back-end operation.
    const updatedMessages = messagesIds
      .map(getters.messageById)
      .map((message) => ({ ...message, unread }));
    commit(ENTITIES_MESSAGES_UPDATE, { messages: updatedMessages });

    // Apply read status to the mailbox and reload folders to update unread count.
    await api.messages.bulk({ messages: messagesIds, update: { unread } });

    return updatedMessages;
  },

  async changeMessagesFlagStatus(
    { commit, getters },
    { messagesIds, flagged }
  ) {
    // Update cached messages so that UI is updated without waiting for the back-end operation.
    const messages = messagesIds
      .map(getters.messageById)
      .map((message) => ({ ...message, flagged }));
    commit(ENTITIES_MESSAGES_UPDATE, { messages });

    // Apply flag status to the mailbox.
    await api.messages.bulk({ messages: messagesIds, update: { flagged } });

    return messages;
  },

  updateDebounceMessageLoading({ commit }, { isLoading }) {
    commit(ENTITIES_MESSAGES_SET_DEBOUNCE_LOADING, { isLoading });
  },
};

const mutations = {
  // Mutations on the list of ids of fully cached messages.
  [ENTITIES_MESSAGES_ADD_FULL](state, { id }) {
    state.fullMessageIds = [...new Set(state.fullMessageIds.concat(id))];
  },
  [ENTITIES_MESSAGES_DELETE_FULL](state, { id }) {
    state.fullMessageIds = state.fullMessageIds.filter(
      (fullMessageId) => fullMessageId !== id
    );
  },
  // Mutations on the cached messages.
  [ENTITIES_MESSAGES_UPDATE](state, { messages: updates }) {
    updates.forEach((update) => {
      const message = state.byId[update.id] || {};
      const updatedMessage = _mergeMessageWithUpdate(message, update);
      Vue.set(state.byId, update.id, updatedMessage);
    });
  },
  [ENTITIES_MESSAGES_DELETE](state, { id }) {
    delete state.byId[id];
  },
  [ENTITIES_MESSAGES_SET_DEBOUNCE_LOADING](state, { isLoading }) {
    state.debouncedMessageLoading = isLoading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
