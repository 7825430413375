// Note: Only feature flag definitions should go here, as this whole module is imported without filtering
export const FEATURE_AUTO_REPLY = 'auto_reply';
export const FEATURE_BROWSER_METRICS = 'browser_metrics';
export const FEATURE_FILTER = 'filter';
export const FEATURE_FILTERS = 'filters';
export const FEATURE_IMAP_TOKENS = 'imap_tokens';
export const FEATURE_IS_CANARY = 'is-canary';
export const FEATURE_MATOMO = 'matomo';
export const FEATURE_RECOVERY = 'recovery';
export const FEATURE_SIGNUP = 'signup';
export const FEATURE_SIGNUP_CAPTCHA = 'signup_captcha';
export const FEATURE_TEAM_ACCOUNT = 'team_accounts';
export const FEATURE_UI_SESSION_EXPIRY = 'ui_session_expiry';
