import debounce from 'debounce';
import { DirectiveOptions } from 'vue';

interface HTMLElementWithOnResize extends HTMLElement {
  onResize: () => void;
}

const HORIZONTAL_SCROLL_ATTRIBUTE = 'data-horizontal-scroll';
const SCROLL_RIGHT_ATTRIBUTE = 'data-horizontal-scroll-direction-right';
const SCROLL_LEFT_ATTRIBUTE = 'data-horizontal-scroll-direction-left';

function onScroll(ev: Event) {
  const el = ev.target as HTMLElement;
  if (el) {
    setScrollIndicator(el);
  }
}

function setScrollIndicator(el: HTMLElement) {
  isScrollableToTheRight(el)
    ? el.setAttribute(SCROLL_RIGHT_ATTRIBUTE, '')
    : el.removeAttribute(SCROLL_RIGHT_ATTRIBUTE);
  isScrollableToTheLeft(el)
    ? el.setAttribute(SCROLL_LEFT_ATTRIBUTE, '')
    : el.removeAttribute(SCROLL_LEFT_ATTRIBUTE);
}

function isScrollableToTheLeft(el: HTMLElement) {
  return el.scrollLeft > 0;
}

function isScrollableToTheRight(el: HTMLElement) {
  return el.scrollWidth - el.offsetWidth !== el.scrollLeft;
}

const resizeListenerOptions: AddEventListenerOptions = {
  passive: true,
};

const horizontalScrollIndicatorDirective: DirectiveOptions = {
  bind(el) {
    el.setAttribute(HORIZONTAL_SCROLL_ATTRIBUTE, '');

    el.addEventListener('scroll', onScroll);

    (el as HTMLElementWithOnResize).onResize = debounce(
      () => setScrollIndicator(el),
      100
    );
    window.addEventListener(
      'resize',
      (el as HTMLElementWithOnResize).onResize,
      resizeListenerOptions
    );
  },

  inserted(el) {
    setScrollIndicator(el);
  },

  unbind(el) {
    el.removeEventListener('scroll', onScroll);
    window.removeEventListener(
      'resize',
      (el as HTMLElementWithOnResize).onResize,
      resizeListenerOptions
    );
  },
};

export default horizontalScrollIndicatorDirective;
